/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  $(document).ready(function () {
    $('button[type=\'submit\']').click(function (e) {
      var formId = $(e.target).attr("form");
      $("form[id*=" + formId + "]").submit();
    });

    $('a.js-popup-iframe').each(function () {
        $this = $(this);
        var href = $this.data("src");

        href: href,
          $this.colorbox({
            href: href,
            'iframe': true,
            innerWidth: '80%',
            innerHeight: '70%',
          });
      });
  });

  countDocsActive = function countDocsActive() {
    nbDocs = $('.document--active').length;
    $('#nb-docs').text(nbDocs);
  };

  resetDocs = function resetDocs() {
    $('.document').each(function () {
      $(this)
        .addClass('document--active')
        .removeClass('document--inactive')
        .closest('.cell')
        .removeClass('hide');
    });

    $('.documents__filter').each(function () {
      $(this).removeClass('documents__filter--active');
    });
    countDocsActive();
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        objectFitImages();

        // ****************************************
        // ****************************************
        // **************** breve *****************
        // ****************************************
        // ****************************************

        // ****************************************
        // GÉRÉ DIRECTEMENT DANS LE TEMPLATE !!!!
        // (oui c'est sale, mais au moins ça marche, lol)
        // ****************************************

        // var close = document.getElementById('breve__close');
        // var overlay = document.getElementById('breve__overlay');
        // var breve = document.getElementById('breve');

        // closeBreve = function closeBreve (){
        //   breve.style.display = "none";
        // }

        // close.onclick = closeBreve;
        // overlay.onclick = closeBreve;
        // function closeBreve(){
        //   $('#breve').hide();
        // }

        // $('.breve__close').click(function(){
        //   closeBreve();
        // });

        // $('.breve__overlay').click(function(){
        //   closeBreve();
        // });

        // ****************************************
        // ****************************************
        // ********* form custom checkbox *********
        // ****************************************
        // ****************************************
        $('.wpcf7-acceptance input[type=checkbox]').change(function () {
          $(this).closest('.wpcf7-acceptance').toggleClass('wpcf7-acceptance--checked');
        });

        $('.ginput_container_consent input[type=checkbox]').change(function () {
          $(this).closest('.ginput_container_consent').toggleClass('ginput_container_consent--checked');
        });

        // ****************************************
        // ****************************************
        // ******** Header profil selector ********
        // ****************************************
        // ****************************************
        $('.profil-selector--current').click(function (e) {
          e.preventDefault();
          $this = $(this);
          $parent = $this.parents('.profil-selector');
          $parent.toggleClass('active');
        });

        // *******************************************
        // *******************************************
        // ** Add picto to menu item with sub-menus **
        // *******************************************
        // *******************************************
        //$('.nav-primary--large li.menu-item-has-children > a')
          //.append('<i class="icon"></i>')
          //.find('i')
          //.load('/app/themes/urm/dist/images/icons/down-arrow.svg');

        //$('.nav-primary--medium li.menu-item-has-children > a')
          //.prepend('<i class="icon"></i>')
          //.find('i')
          //.load('/app/themes/urm/dist/images/icons/arrow-left.svg');

        // ****************************************
        // ****************************************
        // ****** Display sub-menus on hover ******
        // ****************************************
        // ****************************************
        $('.nav-primary li.menu-item-has-children').hover(function (e) {
          $(this).addClass('sub-menu-active');
        }, function () {
          $(this).removeClass('sub-menu-active');
        });

        // ****************************************
        // ****************************************
        // Add class on header if we're on page top
        // ****************************************
        // ****************************************
        function headerGetPos() {
          pos = $(document).scrollTop();
          if (pos <= 0) {
            $('header').removeClass('header--page-not-top');
          } else {
            $('header').addClass('header--page-not-top');
          }
        }
        $(document).scroll(function () {
          headerGetPos();
        });
        headerGetPos();

        // ****************************************
        // ****************************************
        // ** Toogle active state on menu burger **
        // ****************************************
        // ****************************************
        $('#header__burger, #nav-primary__close-resp').click(function () {
          $('.header__right-container').toggleClass('header__right--active');
        });

        // ****************************************
        // ****************************************
        // ********** Init menu drilldown *********
        // ****************************************
        // ****************************************
        var options = {
          cssClass: {
            container: 'menu-drilldown--container',
            root: 'menu-drilldown--root',
            sub: 'menu-drilldown--sub',
            back: 'menu-drilldown--back'
          }
        };
        $('.menu-drilldown').drilldown(options);

        // ****************************************
        // ****************************************
        // Add padding on page top (bc of fixed menu)
        // ****************************************
        // ****************************************
        function pagePaddingTop() {
          headerHeight = $('header.header').outerHeight();
          $('.page-header').css('padding-top', headerHeight + 'px');
        }
        $(window).resize(function () {
          pagePaddingTop();
        });
        pagePaddingTop();

        // ****************************************
        // ****************************************
        // ********** Popup infos reseau **********
        // ****************************************
        // ****************************************
        $('.popup-infos-reseau__close').click(function () {
          $('.popup-infos-reseau').addClass('popup-infos-reseau--closed');
        });

        // ****************************************
        // ****************************************
        // **************** Mod FAQ ***************
        // ****************************************
        // ****************************************
        $('.mod-faq__title').click(function () {
          $this = $(this);
          const parent = $this.parent('.mod-faq__single');

          if (parent.hasClass('mod-faq__single--open')) {
            parent.removeClass('mod-faq__single--open');
            parent.find('.mod-faq__answer').slideUp();
          } else {
            $('.mod-faq__title').each(function () {
              $(this).parent('.mod-faq__single').removeClass('mod-faq__single--open');
              $(this).parent('.mod-faq__single').find('.mod-faq__answer').slideUp();
            });

            parent.addClass('mod-faq__single--open');
            parent.find('.mod-faq__answer').slideDown();
          }
        });

        function modFaqFirst() {
          if ($('.mod-faq__single--first')) {
            $('.mod-faq__single--first').each(function () {
              $(this)
                .addClass('mod-faq__single--open')
                .find('.mod-faq__answer')
                .slideDown();
            });
          }
        }
        modFaqFirst();

        $('.mod-faq__cat-title').click(function () {
          $this = $(this);
          const parent = $this.parent('.mod-faq__cat');

          if (parent.hasClass('mod-faq__cat--open')) {
            parent.removeClass('mod-faq__cat--open');
            parent.find('.mod-faq__questions').slideUp();
          } else {
            $('.mod-faq__cat-title').each(function () {
              $(this).parent('.mod-faq__cat').removeClass('mod-faq__cat--open');
              $(this).parent('.mod-faq__cat').find('.mod-faq__questions').slideUp();
            });

            parent.addClass('mod-faq__cat--open');
            parent.find('.mod-faq__questions').slideDown();
          }
        });

        function modFaqFirstCat() {
          if ($('#mod-faq__cat--first')) {
            $('#mod-faq__cat--first').each(function () {
              $(this)
                .addClass('mod-faq__cat--open')
                .find('.mod-faq__questions')
                .slideDown();
            });
          }
        }
        modFaqFirstCat();

        $('.onglet__title').click(function () {
          $this = $(this);
          id = $this.data('onglet-id');
      
          // Retirer les classes actives des contenus et sous-titres
          $('.onglet__subtitle--active').removeClass('onglet__subtitle--active');
          $('.onglets__content__single--active').removeClass('onglets__content__single--active');
      
          // Ajouter les classes actives au contenu et sous-titre correspondant
          $('#onglet-' + id).addClass('onglets__content__single--active');
          $this.next('.onglet__subtitle').addClass('onglet__subtitle--active');
      
          // Mettre à jour les classes pour les autres onglets
          $('.cell').removeClass('completed');
          $('.cell').removeClass('cell--active');
          $('.onglet__title').removeClass('onglet__title--completed');
          $('.onglet__title').removeClass('onglet__title--active');
      
          // Ajouter 'completed' aux cellules précédentes
          $this.closest('.cell').prevAll().addClass('completed');
          $this.closest('.cell').prevAll().find('.onglet__title').addClass('onglet__title--completed');
      
          // Ajouter 'active' à la cellule courante
          $this.closest('.cell').addClass('cell--active');
      
          // Vérifier si l'onglet actuel est le dernier
          if ($this.closest('.cell').is(':last-child')) {
              $this.addClass('onglet__title--completed');
          } else {
              $this.addClass('onglet__title--active');
          }
      });
      
      

        // ****************************************
        // ****************************************
        // *********** Archive document ***********
        // ****************************************
        // ****************************************
        $('.documents__filter').click(function () {
          $this = $(this);
          var status = $this.data('status');

          if (!$this.hasClass('documents__filter--active')) {
            $('.documents__filter').each(function () {
              $(this).removeClass('documents__filter--active');
            });

            $this.addClass('documents__filter--active');
          }

          $('.document').each(function () {
            if ($(this).hasClass('document--' + status)) {
              $(this)
                .addClass('document--active')
                .removeClass('document--inactive')
                .closest('.cell')
                .removeClass('hide');
            } else {
              $(this)
                .addClass('document--inactive')
                .removeClass('document--active')
                .closest('.cell')
                .addClass('hide');
            }
          });
          countDocsActive();
        });
        // Active default "current" filter
        $(".documents__filter[data-status='current']").trigger('click');

        $('#documents__reset').click(function () {
          resetDocs();
        });

        $('#archive-docs__form input[type=checkbox]').change(function () {
          $this = $(this);
          $li = $this.closest('li');

          if ($this.is(':checked')) {
            // check every child term
            $li.find('input[type=checkbox]').prop('checked', true);
          } else {
            //uncheck every parent term
            $li.parents('li').children('label').children('input').prop('checked', false);
          }
        });

        $('.archive-docs__filters-left-toggle-resp').click(function () {
          $('.archive-docs__filters-left-holder').toggleClass('archive-docs__filters-left-holder--active');
          $('.archive-docs__filters-left').toggleClass('archive-docs__filters-left--active');
        });

        countDocsActive();

        $('.tab__filter').click(function () {
          if (!$(this).hasClass('tab__filter--active')) {
            $('.tab__filter').each(function () {
              $(this).removeClass('tab__filter--active');
            });
            $(this).addClass('tab__filter--active');

            id = $(this).data('id');

            $('.tab__single--active').each(function () {
              $(this).removeClass('tab__single--active');
            });

            $('#tab-' + id).addClass('tab__single--active');
          }
        });

        // ****************************************
        // ****************************************
        // ************ Nous rejoindre ************
        // ****************************************
        // ****************************************
        $('.nous-rejoindre__slider').slick({
          prevArrow: '<div class="slick-arrow slick-prev"><i class="icon icon-arrow-left"><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 129 129" xml:space="preserve"><path d="M94.3 121.3c1.6-1.6 1.6-4.2 0-5.8l-51.1-51 51.1-51.1c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8 0L34.6 61.5c-.8.8-1.2 1.8-1.2 2.9 0 1 .4 2.1 1.2 2.9l53.9 53.9c1.6 1.7 4.2 1.7 5.8.1z"></path></svg></i></div>',
          nextArrow: '<div class="slick-arrow slick-next"><i class="icon icon-arrow-right"><svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 129 129" xml:space="preserve"><path d="M34.6 7.6c-1.6 1.6-1.6 4.2 0 5.8l51.1 51-51.1 51.1c-1.6 1.6-1.6 4.2 0 5.8 1.6 1.6 4.2 1.6 5.8 0l53.9-53.9c.8-.8 1.2-1.8 1.2-2.9 0-1-.4-2.1-1.2-2.9L40.4 7.7C38.8 6 36.2 6 34.6 7.6z"></path></svg></i></div>'
        });

        // ****************************************
        // ****************************************
        // ************ Search results ************
        // ****************************************
        // ****************************************
        $('.search__filters-single').click(function () {
          if ($(this).hasClass('search__filters-single--selected')) {
            $(this).removeClass('search__filters-single--selected');

            $('.search-result__item').each(function () {
              $(this).removeClass('search__filters-single--hidden');
            });
          } else {
            $('.search__filters-single').each(function () {
              $(this).removeClass('search__filters-single--selected');
            });

            $(this).addClass('search__filters-single--selected');
            var id = $(this).attr('id');
            console.log(id);

            $('.search-result__item').each(function () {
              if (!$(this).hasClass(id)) {
                $(this).addClass('search__filters-single--hidden');
              } else {
                $(this).removeClass('search__filters-single--hidden');
              }
            });
          }
        });

        // ****************************************
        // ****************************************
        // ************* Header search ************
        // ****************************************
        // ****************************************
        $('.header__search .header--icon').click(function () {
          toggleSearchForm();
        });

        $('.header__search-form .icon-close').click(function () {
          toggleSearchForm();
        });

        function toggleSearchForm() {
          $('.header__search-form').toggleClass('header__search-form--active');
        }

        // ****************************************
        // ****************************************
        // mod txt chiffre animate numbers increase
        // ****************************************
        // ****************************************
        $('.mod-titre-txt-chiffres').each(function () {
          $('.mod-titre-txt-chiffres__single-nb .num').each(function () {
            $(this).text('0');
          });
        });

        var modNbScrolled = false;
        $(window).scroll(function () {
          if ($('.mod-titre-txt-chiffres').length !== 0) {
            var oTop = $('.mod-titre-txt-chiffres').offset().top - window.innerHeight;
            if (!modNbScrolled && $(window).scrollTop() > oTop) {
              $('.mod-titre-txt-chiffres__single-nb .num').each(function () {
                var $this = $(this),
                  countTo = $this.attr('data-count');
                $({
                  countNum: $this.text()
                }).animate({
                  countNum: countTo
                }, {
                  duration: 2000,
                  easing: 'swing',
                  step: function () {
                    $this.text(Math.floor(this.countNum));
                  },
                  complete: function () {
                    $this.text(this.countNum);
                  }
                });
              });
              modNbScrolled = true;
            }
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
